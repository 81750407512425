/* Region CustomTextInput */

@font-face {
  font-family: "InstagramSans";
  src: url("../assets/font/HelveticaNeueLight.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Apply the font globally */
body {
  font-family: "Helvetica", sans-serif;
  letter-spacing: 0.8px;
}

.custom-text-input-container {
  display: flex;
  align-items: center;
  appearance: none;
  border-color: var(--input-inactive-border-color);
  border-width: 1px;
  padding-top: 8px /* 8px */;
  padding-bottom: 8px /* 8px */;
  border-radius: 2px;
  background-color: transparent;
  height: 48px;
}

.custom-text-input {
  border: none;
  display: flex;
  flex: 1;
  outline: none;
  width: 100%;
  padding-left: 4px;
  background-color: transparent;
}

.error-message {
  width: 100%;
}

.text-input-leading {
  padding-left: 4px;
}

.text-input-trailing {
  padding-left: 8px;
  padding-right: 8px;
}

/* Region CustomTextArea */

.custom-text-area-container {
  display: flex;
  align-items: center;
  appearance: none;
  border-color: var(--input-inactive-border-color);
  border-width: 1px;
  border-radius: 2px;
  padding-top: 8px /* 8px */;
  padding-bottom: 8px /* 8px */;
  border-radius: 2px;
  background-color: transparent;
}

.custom-text-area {
  border: none;
  display: flex;
  flex: 1;
  outline: none;
  width: 100%;
  padding-left: 4px;
  background-color: transparent;
}

/* Region DropDown  */
.custom-dropdown-container {
  position: relative;
  width: 100%;
  height: 34px;
}

.dropdown-button {
  position: relative;
  width: 100%;
  padding-top: 4px;
  height: 100%;
  border-color: var(--input-active-border-color);
  border-bottom-width: 2px;
  cursor: pointer;
  text-align: left;
}

.dropdown-button.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.dropdown-content-option {
  position: absolute;
  left: 0;
  width: 100%;
  overflow-y: auto;
  z-index: 1;
  background-color: white;
  border: 1px solid #9ca3af;
}

.option {
  padding: 10px;
  cursor: pointer;
}

/* Region Button  */
.custom-button {
  width: 100%;
  border-radius: 9999px;
  background-color: var(--primary-button-color);
  padding: 12px 24px;
  box-shadow: 0 4px 9px -4px var(--primary-button-shadow-color);
}

/* .custom-button:hover {
  background-color: var(--primary-button-hover-color);
} */

button[disabled]:hover {
  background: #c6c9c9;
}

/* Region  CustomDropDown With Grouping   */

.custom-dropdown-group {
  position: relative;
  width: 200px;
}
.sticky-label-group {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
  font-weight: bold;
  z-index: 1;
}

.selected-value-group {
  border: 1px solid #ccc;
  padding: 8px;
  cursor: pointer;
  background-color: #fff;
  position: relative;
}

.selected-value.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.arrow-dropdown-group {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.selected-value.open .arrow {
  transform: translateY(-50%) rotate(180deg);
}

.options-group {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  overflow-y: scroll;

  height: 300px;
}

.group-label-group {
  background-color: #f2f2f2;
  padding: 8px;
  font-weight: bold;
}

.option-group {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  padding-left: 25px;
}

.option:hover {
  background-color: #f2f2f2;
}

.option.selected {
  background-color: #cce6ff;
}

.modal-overlay-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-horizontal-line-space {
  margin-left: -1.25rem /* -20px */;
  margin-right: -1.25rem /* -20px */;
}

.modal-custom {
  background-color: white;
  padding: 0px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 99;
}

.title-custom-model {
  margin-inline-end: auto;
}

.custom-modal-div {
  display: flex;
  align-items: center;
}
.close-button-model {
  font-size: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: center; /* Horizontally center the logo */
  align-items: center; /* Vertically center the logo */
  padding-top: 10px;
}
.message-custom-model {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.button-container-model {
  display: flex;
  justify-content: center;
}

.cancel-button-model,
.confirm-button-model {
  margin: 0 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button-model {
  background-color: #ccc;
}

.confirm-button-model {
  background-color: #007bff;
  color: white;
}

.searchable-dropdown {
  position: relative;
  width: 200px;
}

.dropdown-header-searchable {
  border: 1px solid #ccc;
  padding: 8px;
  cursor: pointer;
  background-color: white;
}

.dropdown-options-searchable {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 2;
  border-top: none;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  /* overflow-x: none; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.option-searchable {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.option-searchable:hover {
  background-color: #f2f2f2;
}

.search-dropdown-custom-input {
  width: 100%;
  padding: 8px;
  border: none;
  border: 1px solid #9ca3af;
  border-top: 0px;
  outline: none;
  overflow: auto;
}

/* Custom Date Picker */
.custom-date-picker {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
  top: 0%;
}

.selected-date {
  cursor: pointer;
  margin-top: 8px;
  margin-left: 4px;
  border: 1px solid white;
  /* border-radius: 4px; */
  position: absolute;
  bottom: 27px;
}

.date-picker-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.year-list,
.month-list,
.day-grid {
  list-style: none;
  padding: 0;
  margin: 0;
}

.year-list li,
.month-list li,
.day-grid li {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.day-grid li {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.day-grid li.selected {
  background-color: #007bff;
  color: white;
}

/* Hide native input */
.hidden-input {
  display: none;
}

/* CustomFileUpload.css */
.custom-file-upload {
  display: inline-block;
  font-family: Arial, sans-serif;
  border: 1px solid #9ca3af;
}

.file-upload-container {
  border: 1px solid #9ca3af;
  width: 100%;
}

.file-upload-container-child-div {
  display: flex;
  height: 150px;
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
}
.file-upload-title-div {
  /* margin-inline-end: auto; */
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: #cce6ff; */
  padding-bottom: 10px;
}
.file-label {
  background-color: var(--primary-color);
}
.my-drag-drop {
  width: 100%;
  margin-inline-end: auto;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(229 231 235);
  border: 1px dashed #9ca3af;
}
.highlight-drag-part {
  background-color: rgb(241, 241, 136);
  border: 1px solid black;
}

.my-drag-drop:hover {
  cursor: pointer;
}
.drag-and-drop-file-upload {
  background-color: #007bff;
}

.info-icon-file-upload {
  display: flex;
  align-items: center;
}
.selected-upload-file-name-div {
  display: flex;
  margin-inline-end: auto;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}
.error-color-file-upload {
  color: red;
}
.selected-file-div {
  display: flex;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  border-width: 1px;
  color: green;
}
.readonly-tag {
  display: inline-block;
  background-color: var(--text-favorite-color);
  color: var(--text-readonly-tag-color);
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 20px;
}
.file-input {
  display: none;
}

.file-label {
  display: inline-block;
  cursor: pointer;
  padding: 3px 6px;
  border-radius: 4px;
  transition: background-color 0.3s;
  border: 1px solid #9ca3af;
  width: 50%;
  text-align: center;
  color: var(--primary-btn-text-color);
}

.file-label:hover {
  background-color: #0056b3;
  color: white;
}

/* Region  Tooltip */

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: var(--tooltip-color);
  color: var(--tooltip-text-color);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip-container:hover .tooltip-text {
  opacity: 1;
  visibility: visible;
}

.top-tooltip .tooltip-container .tooltip-text {
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.right-tooltip .tooltip-container .tooltip-text {
  top: -5px;
  left: 105%;
}

.left-tooltip .tooltip-container .tooltip-text {
  top: -5px;
  right: 105%;
}

/* Region Tost Message */

.toast-header {
  display: flex;
  justify-content: end;
}
.animate-pulse {
  animation: pulse 0.5s cubic-bezier(0.4, 0, 0.6, 1);
}

@keyframes pulse {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

/* Region Custom Date/Time Picker */
.ic-date-input-container {
  display: flex;
  align-items: center;
  /* position: relative; */
  border-color: var(--input-inactive-border-color);
  border-width: 1px;
  border-radius: 2px;
  appearance: none;
  padding-top: 8px /* 8px */;
  padding-bottom: 8px /* 8px */;
  padding-left: 10px;
  background-color: transparent;
  height: 48px;
}

.ic-date-input {
  color: transparent !important;
  outline: none;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  border: none;
  background-color: transparent !important;
  mix-blend-mode: color;
}

.ic-date-input::-webkit-calendar-picker-indicator {
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
/* Endregion */

/*--Region-FloatingInput-Start-----*/
.floating-input .custom-text-input-container {
  position: relative;
}
.floating-input .custom-text-input-container:focus-within {
  border-color: var(--input-active-border-color);
}

.floating-input label {
  position: absolute;
  background: transparent;
  transition: all 0.3s;
  pointer-events: none;
}

.floating-input .custom-text-input:focus + label,
.floating-input .custom-text-input:not(:placeholder-shown) + label {
  top: 0;
  left: 10px;
  transform: translateY(-60%);
  background: var(--bg-required-label);
  padding: 2px 6px 4px 6px;
  color: var(--input-placeholder-color);
  font-size: 14px;
  line-height: 12px;
  border-radius: 10px;
  border: 0.5px solid #dcdcdc;
}

.floating-text-area .custom-text-area-container {
  position: relative;
}
.floating-text-area .custom-text-area-container:focus-within {
  border-color: var(--input-active-border-color);
}

.floating-text-area label {
  position: absolute;
  padding-top: 1px;
  background: transparent;
  transition: all 0.3s ease;
  pointer-events: none;
  color: var(--input-placeholder-color);
  opacity: 0;
}

.floating-text-area .custom-text-area:focus + label,
.floating-text-area .custom-text-area:not(:placeholder-shown) + label {
  top: 0;
  left: 10px;
  transform: translateY(-60%);
  padding: 2px 6px 4px 6px;
  background: var(--bg-required-label);
  border-radius: 10px;
  border: 0.5px solid #dcdcdc;
  color: var(--primary-title-color);
  font-size: 14px;
  line-height: 16px;
  opacity: 1;
}

.floating-dropDown-label {
  /* position: absolute; */
  pointer-events: none;
  padding: 2px 6px 4px 6px;
  background: var(--bg-required-label);
  border-radius: 10px;
  border: 0.5px solid #dcdcdc;
  left: 8px;
  top: 0;
  transition: all 0.3s;
}

.floating-timePicker {
  pointer-events: none;
  left: 8px;
  transform: translateY(-50%);
  padding: 2px 6px 2px 6px;
  background: var(--bg-required-label);
  border-radius: 10px;
  border: 0.5px solid #dcdcdc;
  transition: all 0.3s ease;
}

.floating-datePicker {
  pointer-events: none;
  left: 8px;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  padding: 2px 6px 2px 6px;
  background: var(--bg-required-label);
  border-radius: 10px;
  border: 0.5px solid #dcdcdc;
}
/*--Region-FloatingInput-End-----*/

.comman-disablebtn {
  background-color: #c6c9c9;
  border-radius: 25px;
}

.login-select {
  height: 48px;
  border-left: 1px solid var(--input-inactive-border-color);
  border-top: 1px solid var(--input-inactive-border-color);
  border-bottom: 1px solid var(--input-inactive-border-color);
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* ---------- Comman Fonts ---------------------------------------------*/

.comman-grey {
  color: var(--primary-description-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.otp-timer {
  color: #696f6f;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.comman-black-text {
  color: var(--primary-title-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.comman-black-big {
  color: var(--primary-title-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.comman-black-lg {
  color: var(--primary-title-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.common-black-xl {
  color: var(--primary-title-color);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.comman-black {
  color: var(--primary-title-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.comman-white-text {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.comman-white-sm {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.comman-white-xl {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.comman-SubFont {
  color: var(--primary-description-color);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
}

.comman-question-text {
  color: var(--primary-title-color);
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.identity-big-text {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.comman-blue {
  color: var(--primary-link-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.light-grey {
  color: #c3c3c3;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

::placeholder {
  color: var(--primary-placeholder-color) !important;
}

textarea::placeholder {
  color: var(--primary-placeholder-color) !important;
}

.comman-padding {
  padding: 15px;
}
/* ---------------------------------------------------------------------------------------------------- end ----------------------------- */
.cursor:hover {
  cursor: pointer;
}
.story-x {
  bottom: 196px;
  left: 359px;
}
.cursor a {
  height: 6rem;
}

.cursor a span {
  font-size: 25px;
  font-weight: 700;
}

.top {
  margin-top: 25px;
}

.select {
  position: relative;
  /* margin-bottom: 15px; */
  width: 296px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.select .selectBtn {
  background: var(--bg1);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff;
}
.select .selectBtn:after {
  content: "";
  position: absolute;
  top: 45%;
  right: 15px;
  width: 6px;
  height: 6px;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  border-right: 2px solid #666;
  border-bottom: 2px solid #666;
  transition: 0.2s ease;
}
.select .selectBtn.toggle {
  border-radius: 3px 3px 0 0;
}
.select .selectBtn.toggle:after {
  -webkit-transform: translateY(-50%) rotate(-135deg);
  transform: translateY(-50%) rotate(-135deg);
}
.select .selectDropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  background: var(--bg1);
  border-top: 1px solid #eee;
  z-index: 1;
  background: #fff;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  visibility: hidden;
  transition: 0.2s ease;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}
.select .selectDropdown .option {
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.select .selectDropdown .option:hover {
  background: #f8f8f8;
}
.select .selectDropdown.toggle {
  visibility: visible;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.background-green {
  background: var(--primary-button-color);
}

.sidebar-hover:hover {
  background: var(--primary-button-hover-color);
}

p.alt-mobile {
  color: var(--primary-title-color);
  font-size: 35px;
  font-weight: 700;
}

.radio-button-input[type="radio"]:checked,
.radio-button-input.checked {
  accent-color: var(--primary-background-color);
  /* Add any additional styling for the checked state */
}

.checkbox-color {
  accent-color: var(--primary-background-color);
  height: 19px;
  width: 19px;
}

.bg-accent-color {
  background-color: var(--accent-color);
}

.input-border {
  --tw-border-opacity: 1;
  border-color: #99b5a1;
}

.trainer-font {
  color: var(--primary-title-color);
  font-size: 30px;
  font-weight: 500;
}
/* ---------------------bg-here------------------------- */
.main-bg {
  background: var(--main-background-color);
}
.layout-bg {
  background: var(--defult);
}
.svg-color {
  color: var(--primary-background-color);
}

.bg-main-primary {
  background: var(--primary-background-color);
}

.services-bg {
  border-radius: 10px;
  border: 1px solid var(--primary-button-color);
  background: var(--services-background-color);
}

/* ---------------------bg-here------------------------- */

.main-Certificates {
  background: var(--defult);
}

.main-identity {
  background: var(--primary-background-color);
}

.Certificates-container {
  border-radius: 25px 25px 0px 0px;
  background: #fff;
  padding: 15px;
}

.trainer-Certificates-buttons {
  padding: 15px;
  background: white;
}

.active {
  background: var(--primary-button-color);
  color: #fff;
}

.comman-btn {
  background: var(--primary-button-color);
  border-radius: 25px;
}

.theme-bg {
  color: var(--primary-button-color);
}

.comman-btn-home {
  border-radius: 15px;
}

.comman-border {
  border-radius: 10px;
  border: 1px solid var(--primary-button-color);
}

.comman-text-white {
  color: var(--on-primary-color);
}
.common-search {
  height: 40px;
  border-radius: 10px;
  outline: none;
  position: relative;
  background: white;
  border: 1px solid #9f9f9f;
}
.slide-image {
  width: 90px;
}
.common-search::placeholder {
  color: #c3c3c3;
  font-size: 14px;
  line-height: 19.5px;
}

.search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 4.2px;
  left: 5px;
  z-index: 999;
}

.profile-question {
  padding: 6px;
  border: 0.7px solid var(--primary-background-color);
  border-radius: 10px;
}

.profile-number {
  width: 60px;
  height: 60px;
  background: var(--primary-background-color);
  border-radius: 10px;
  font-size: 40px;
}

.border-box {
  height: 254px;
  border: 1px dashed #9a9898;
}

.home-image {
  width: 40px;
  height: 40px;
}
.web-shadow {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
}
.web-border-hover > button {
  position: relative;
}
.web-border-hover:after {
  position: absolute;
  top: 100%;
  left: 0%;
  width: 0;
  height: 2px;
  background: var(--primary-background-color);
  display: block;
  content: "";
  transition: width 0.5s ease-in-out;
}
.web-border-hover:hover:after {
  width: 100%;
}

.home-image-web {
  width: 3.5rem;
  height: 3.5rem;
  /* margin-bottom: 20px; */
}
.story-image {
  width: 70px;
  height: 70px;
}

.border-custom {
  --tw-border-opacity: 1;
  border-color: var(--primary-background-color);
  border-width: 2px;
}

.bottom-navbar {
  border-radius: 30px 30px 0px 0px;
  background: #fff;
  box-shadow: 0px -2px 11px 0px rgba(0, 0, 0, 0.25);
  z-index: 998;
}
.event-description {
  border-radius: 25px 25px 0px 0px;
  background: var(--background-color);
  border-top: 2px solid var(--primary-background-color);
  box-shadow: 5px var(--primary-background-color);
}
.service-padding {
  padding: 0px 5px 0px 5px;
}

.box-shadow {
  box-shadow: 1px 2px 9.2px 0px rgba(181, 181, 181, 0.25);
}

.service-description {
  top: 276px;
  background: var(--background-color);
  border-top: 2px solid var(--primary-background-color);
  box-shadow: 5px var(--primary-background-color);
}

.details-bg {
  background: #2d5164;
}

.service-box {
  height: 67px;
  border-radius: 10px;
  border: 1px solid var(--primary-button-color);
  width: -moz-fit-content;
  margin-right: 20px;
  padding: 12px;
  white-space: nowrap;
}
.margin-all {
  margin-right: 68px;
  margin-left: 68px;
}

.date-container {
  border-radius: 20px;
  border: 0.5px solid var(--primary-button-color);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  height: 65px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
}

.date-without-border {
  height: 65px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.time-container {
  padding: 5px 13px 5px 12px;
  border-radius: 4px;
  border: 1px solid var(--primary-button-color);
  width: fit-content;
}

.booking-info {
  border-radius: 10px;
  border: 1px solid var(--primary-button-color);
  padding: 15px;
}

.profile-image {
  width: 93px;
  height: 93px;
}

.sidebar .text {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  white-space: unset;
  opacity: 1;
}

.sidebar .image {
  min-width: 60px;
  display: flex;
  align-items: center;
}
.MuiPaper-root {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-service-desc {
  height: 375px;
}
.rdw-editor-toolbar {
  display: none;
}
.demo-editor.rdw-editor-main {
  display: none;
}
/* Sidebar */
.sidebar {
  top: 0;
  left: 0px;
  height: 100%;
  width: 250px;
  background: white;
  padding: 8px;
  transition: all 0.5s ease;
}

.sidebar header {
  position: relative;
}
.sidebar .image-text img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
}

.header .image-text .header-text {
  display: flex;
  flex-direction: column;
}

.header-text .main {
  font-weight: 600;
}

.header-text .sub {
  margin-top: -2px;
}

.sidebar header .toggle {
  position: absolute;
  top: 105%;
  right: -21px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background: var(--primary-background-color);
  /* display: flex; */
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--sidebar-color);
  font-size: 22px;
}

.sidebar li {
  height: 70px;
  /* background-color: red; */
  margin-top: 10px;
  list-style: none;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.sidebar li .icons {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  font-size: 18px;
}
.sidebar li .icons,
.sidebar li .text {
  color: var(--text-color);
  transition: var(--trans-03);
}

.sidebar .search-bar input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 6px;
  background-color: var(--primary-color-light);
}
.sidebar .search-bar {
  background-color: var(--primary-color-light);
}

.sidebar li a {
  height: 100%;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.nav-link {
  margin-bottom: -10px;
}
.sidebar li a:hover {
  background: var(--services-background-color);
  cursor: pointer;
}
.sidebar li a:hover .icons,
.sidebar li a:hover .text {
  color: var(--dark-color);
}

.sidebar .menu-bar {
  /* height: 100%;
  height: calc(100% - 50px); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-toggle {
  display: none;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.hamburger-icon span {
  width: 25px;
  height: 3px;
  background-color: #000; /* Adjust color as needed */
  margin: 3px 0;
  display: block;
}

.menu-bar .mode {
  position: relative;
  border-radius: 6px;
  background: var(--primary-color-light);
}

.menu-bar .mode .moon-sun {
  height: 50px;
  width: 60px;
  display: flex;
  align-items: center;
}
.menu-bar .mode i {
  position: absolute;
  transition: var(--trans-03);
}

.menu-bar .mode i.sun {
  opacity: 0;
}
body.dark .menu-bar .mode i.sun {
  opacity: 1;
}
body.dark .menu-bar .mode i.moon {
  opacity: 0;
}
.menu-bar .mode .toggle-switch {
  position: absolute;
  right: -10px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  background: var(--primary-color-light);
  cursor: pointer;
}

.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 44px;
  background-color: var(--toggle-color);
  border-radius: 25px;
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: var(--sidebar-color);
}

.sidebar.close {
  width: 78px;
}

.sidebar.close .text {
  opacity: 0;
}

body.dark .search-bar input {
  color: white;
}

.sidebar.close .toggle {
  transform: translateY(74%);
}
.profile-image {
  height: 100px;
  width: 100px;
}

.profile-container {
  border-radius: 25px 25px 0px 0px;
  background: #fff;
}
.profile-height {
  height: calc(90vh - 220px);
}
.profile-list-container {
  border-radius: 25px 25px 0px 0px;
  background: #fff;
  overflow: auto;
  margin-top: 25px;
  scrollbar-width: none !important;
}

.border-bottom {
  border-bottom: 0.1px solid var(--primary-button-color);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 30px 15px 30px;
}
.profile-border-bottom {
  border-bottom: 0.1px solid var(--primary-button-color);
  padding: 20px 30px 15px 30px;
}
.profile-btn {
  width: fit-content;
  border-radius: 10px;
  background: var(--primary-background-color);
  padding: 10px 25px;
}

.profile-width {
  width: 40%;
}

.border-profile {
  --tw-border-opacity: 1;
  border-color: #fff;
  border-width: 2px;
}
.triangle-for-filter {
  position: absolute;
  top: 67px;
  right: 25px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}
.triangleForprofile {
  position: absolute;
  top: 81px;
  /* right: 31px; */
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--primary-background-color);
}
.triangle {
  position: absolute;
  top: 82px;
  /* right: 75px; */
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}
.bg-service {
  background-color: #2d5164;
}
.close-sidebar {
  display: none;
}

.searchbar {
  height: auto;
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 64px;
}
.recent-box {
  border-radius: 10px;
  border: 0.7px solid var(--primary-background-color);
  width: fit-content;
  padding: 5px 10px;
}

.active-border {
  border-bottom: 2px solid black;
}

.active-text {
  color: var(--primary-title-color);
}
.inactive-text {
  color: #b7babe;
}

.search-img-box {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
}

.video-icon {
  height: 30px;
  width: 30px;
  background: rgba(0, 0, 0, 0.47);
  position: absolute;
}

.message-image {
  width: 50px;
  height: 50px;
}

.message-border-bottom {
  border-bottom: 1px solid var(--primary-background-color);
}
.message-container {
  border-bottom: 1px solid var(--primary-background-color);
}

.red {
  background: red;
  height: 59px;
  width: 59px;
}

.grey {
  background: grey;
  height: 59px;
  width: 59px;
}

.tag-box {
  padding: 8px;
  width: fit-content;
  background: var(--services-background-color);
  border-radius: 10px;
}

.text-area {
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 0px 9.2px 0px rgba(181, 181, 181, 0.25);
  outline: none;
  padding: 15px;
}

.post-image {
  width: 70px;
  height: 70px;
  border: 1px solid white;
}

.position-relative {
  position: relative;
}

.transition-transform {
  transition: transform 0.5s ease-in-out;
}

.transform-translate-y-0 {
  transform: translateY(0);
}

.transform-translate-y-full {
  transform: translateY(100%);
}

.cansel-image {
  width: 41px;
  height: 41px;
}

.border-white {
  border: 1px solid white;
}
.border-left {
  border-left: 1px solid var(--on-background-color);
}
.centered-image {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
.custom-modal .modal-content .layer:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.react-responsive-modal-modal {
  max-width: 100%;
  height: 100%;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: auto;
}

.react-responsive-modal-closeButton {
  display: none;
}

.booking-card {
  border-bottom: 1px solid var(--primary-background-color);
}

.MuiPaper-root {
  height: 300px;
  width: 100%;
  background: transparent !important;
  box-shadow: none !important;
}
.css-ohwg9z {
  width: 100%;
}
.booking-amount {
  padding: 10px 0px;
}
.success-icon.flex {
  height: 20px;
}

.black-background {
  background: rgba(0, 0, 0, 0.51);
}

.transition-transform-new {
  transition: all 1s ease-in-out;
  z-index: 999;
}

.translate-y-0 {
  transform: translateY(0);
}

.translate-y-full {
  transform: translateY(100%);
}

.edit-input-bg {
  background: rgb(129 121 121 / 51%);
}

.task-box.top {
  height: 325px;
  overflow-x: scroll;
}

.task-box-blue {
  border-right: 2px solid #3b82f6;
}

.task-box-green {
  border-right: 2px solid #00e124;
}
.task-box-grey {
  border-right: 2px solid #6b7280;
}

.add-post-icon {
  width: 48px;
  height: 48px;
  z-index: 999;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 30px;
  right: 40px;
  box-shadow: 0px 0px 4px 0px var(--shadow-favorite-color);
}
.add-icon {
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.43);
  z-index: 999;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 30px;
  right: 40px;
  box-shadow: 0px 0px 4px 0px var(--shadow-favorite-color);
}

.input-shadow {
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 0px 9.2px 0px rgba(181, 181, 181, 0.25);
}

.post-input {
  position: absolute;
  right: 3px;
  bottom: 1px;
}

.input-height {
  height: 50px;
  outline: none;
  padding-left: 2rem;
}

.input-height::placeholder {
  color: #c3c3c3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 2rem;
  display: flex;
  top: 1rem;
}
.previousPage {
  transform: rotate(180deg);
}

.input-placehoder::placeholder {
  color: #c3c3c3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 2rem;
  top: 1rem;
}

.text-area {
  position: relative;
  padding-left: 2rem;
  height: 161px;
}

.height {
  height: 50px;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  box-shadow: 0px 0px 4px 0px var(--shadow-favorite-color);
  border-radius: 22px;
}

.z-index {
  z-index: 999;
}

.max-height {
  max-height: 30rem;
}
.header-bg {
  background: transparent;
  padding: 10px;
  height: 76px;
}

.svg-color {
  color: var(--primary-icon-color);
}

.post-model {
  padding: 35px 100px;
}

svg.close-model-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.vid-sec {
  position: relative;
  bottom: 94%;
  right: 2%;
}
.background-border {
  border-radius: 20px;
  border: 1px solid #f8f9fa;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
}

input.ml-5.border-b-2.focus\:outline-none.w-full {
  background: transparent;
}
.mess-delete {
  display: none;
}

.like-story {
  position: relative;
  top: 45rem;
  left: 29rem;
}
.like-story-1 {
  position: relative;
  top: 36rem;
  left: 27rem;
}
.point > canvas {
  width: 100% !important;
  height: 100% !important;
}
.course-container {
  height: 600px;
}
.show-course {
  border-radius: 5px;
  text-align: center;
  height: 32px;
  width: 180px;
  position: fixed;
  top: 177px;
  right: -80px;
  transform: rotate(270deg);
}
.options {
  width: 160px;
}

ul.accordion-list {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 0;
  margin-top: 10px;
  list-style: none;
}
ul.accordion-list li {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 20px;
  margin: 0 auto 15px auto;
  border: 1px solid #eee;
  border-radius: 5px;
  cursor: pointer;
}
ul.accordion-list li.active h3:after {
  transform: rotate(45deg);
}
ul.accordion-list li h3 {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 0 0 0 0;
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.01em;
  cursor: pointer;
}
ul.accordion-list li h3:after {
  content: "\f278";
  font-family: "material-design-iconic-font";
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.5s ease-in-out;
  font-size: 18px;
}
ul.accordion-list li div.answer {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}
ul.accordion-list li div.answer {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;
  overflow: hidden; /* Ensure content doesn't overflow during animation */
  max-height: 0; /* Start with a height of 0 */
  transition: max-height 0.5s ease-in-out; /* Add a transition for smooth animation */
}
.fav-video > .border-library:has(div) {
  height: 100% !important;
}
.fav-video > .border-library {
  border: 1px solid;
  border-radius: 5px;
  height: 100% !important;
}
.border-library {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}
.fav-video > div > div {
  height: 100% !important;
}
.border-library > .lib-video > .plyr {
  height: 100% !important;
}
.lib-video {
  height: 100% !important;
}
.plyr--video {
  height: 100%;
}
.fav-video {
  height: 100% !important;
}
.services-box {
  height: 145px;
}

.player-card {
  background-color: #f1f4f9;
}

.track-duration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.track-duration > p {
  font-size: 10px;
  font-weight: 500;
  color: #18181f;
  margin: 0;
}

.favorites-icon {
  height: 25px;
  width: 25px;
}
.favorites {
  height: 40px;
  width: 40px;
  background: #ffffff9c;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px var(--shadow-favorite-color);
  z-index: 1;
}

.audio-img {
  height: 40px;
}
.favorite-img {
  height: 400px;
}

.identity-delete {
  position: absolute;
  right: 50px;
  top: -3px;
}

.identity-file {
  position: absolute;
  right: 7px;
  top: -1px;
}

.trainer-certificate {
  top: 65px;
  border-radius: 25px 25px 0px 0px;
  background: var(--background-color);
  border-top: 2px solid var(--services-background-color);
}

.review-helth {
  border: 0.7px solid var(--primary-background-color);
  border-radius: 10px;
  padding: 15px;
}

.application-overview-complete {
  border-right: 15px solid var(--profile-completed-color);
}

.application-overview-progress {
  border-right: 15px solid var(--profile-progress-color);
}

.application-overview-pending {
  border-right: 15px solid var(--profile-pending-color);
}

.carouselpwa {
  background-color: var(--bg-carouselpwa-color);
}

.min-width {
  min-width: 70%;
}

.trainer-availability {
  top: 65px;
  border-radius: 25px 25px 0px 0px;
  background: var(--background-color);
  border-top: 2px solid var(--services-background-color);
}

.trainer-profile-detail {
  top: 30px;
  border-radius: 25px 25px 0px 0px;
  background: var(--background-color);
  border-top: 2px solid var(--services-background-color);
}
.bg-tranceperent-input {
  background: transparent;
}

.favorites-list {
  list-style: none;
  padding: 0px;
}

.help-center {
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 7px;
}

.cross-icon {
  position: absolute;
  top: 17px;
  right: 0;
}

.overview-back {
  padding: 1px 10px;
  border: 1px solid grey;
  border-radius: 25px;
}

.model-disable {
  z-index: 998;
  background-color: var(--background-color-model);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  transition: opacity 0.4s ease-in-out;
}

.switch-image {
  height: 55px;
  width: 55px;
}

/* message textinput */
.box-text-input {
  box-sizing: border-box;
}

button.rws-icon[aria-label="copy"] {
  display: none;
}

.notification-cal-hight {
  height: calc(100% - 30%);
}

.prefered-theme {
  height: 50px;
  border: 1px solid #d3d2d2;
}
.black-bg {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
#image-1 {
  position: absolute;
  top: 10%;
  left: 35%;
  width: 7.5%;
  z-index: 1;
}
#text-1 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  opacity: 0;
  width: 10%;
  z-index: 999;
}
.parallax-container {
  height: 2100vh;
  overflow-y: auto;
  position: relative;
}
.parallax-images {
  z-index: 1;
  position: fixed;
  /* top: 246px; */
  top: 50%;
  transform: translate(0%, -50%);
  /* height: 400px; */
  width: 100%;
  display: flex;
  justify-content: center;
}
.bubble-container {
  width: 10rem;
  height: 10rem;
  z-index: 999;
  position: absolute;
  bottom: 115%;
  left: -17%;
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-0 {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  position: absolute;
  /* bottom: 115%;
  left: -17%; */
  animation-duration: 2s;
  /* animation-name: rise; */
  animation-iteration-count: 1;
}
.bubble-container-1 {
  width: 10rem !important;
  z-index: 999;
  height: 10rem;
  position: absolute;
  /* bottom: 0%; */
  /* left: 0%; */
  animation-duration: 2s;
  /* animation-name: rise; */
  animation-iteration-count: 1;
}
.bubble-container-2 {
  width: 10rem !important;
  z-index: 999;
  height: 10rem;
  position: absolute;
  /* bottom: 115%;
  left: -17%; */
  animation-duration: 2s;
  /* animation-name: rise; */
  animation-iteration-count: 1;
}
.bubble-container-3 {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  position: absolute;
  /* bottom: 115%;
  left: -17%; */
  animation-duration: 2s;
  /* animation-name: rise; */
  animation-iteration-count: 1;
}
.bubble-container-4 {
  width: 10rem;
  height: 10rem;
  z-index: 999;
  position: absolute;
  /* bottom: 115%;
  left: -17%; */
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-5 {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  position: absolute;
  /* bottom: 115%;
  left: -17%; */
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-6 {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  position: absolute;
  /* bottom: 115%;
  left: -17%; */
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-13 {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  background-color: #e3c5f3;
  border-radius: 50%;
  position: absolute;
  /* bottom: 115%;
  left: -17%; */
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-14 {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  background-color: #e3c5f3;
  border-radius: 50%;
  position: absolute;
  /* bottom: 115%;
  left: -17%; */
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-15 {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  background-color: #e3c5f3;
  border-radius: 50%;
  position: absolute;
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-16 {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  background-color: #e3c5f3;
  border-radius: 50%;
  position: absolute;
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-17 {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  background-color: #e3c5f3;
  border-radius: 50%;
  position: absolute;
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-18 {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  background-color: #e3c5f3;
  border-radius: 50%;
  position: absolute;
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-19 {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  background-color: #e3c5f3;
  border-radius: 50%;
  position: absolute;
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-13-reverse {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  position: absolute;
  top: 64%;
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-14-reverse {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  position: absolute;
  top: 64%;
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-15-reverse {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  position: absolute;
  top: 64%;
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-16-reverse {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  position: absolute;
  top: 64%;
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-17-reverse {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  position: absolute;
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-18-reverse {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  position: absolute;
  /* top: 64%; */
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble-container-19-reverse {
  width: 10rem;
  z-index: 999;
  height: 10rem;
  position: absolute;
  /* top: 64%; */
  animation-duration: 2s;
  animation-name: rise;
  animation-iteration-count: 1;
}
.bubble {
  background: radial-gradient(
    circle,
    rgba(187, 222, 222, 1) 0%,
    rgba(219, 238, 238, 0) 53%,
    rgb(255 255 255) 73%,
    rgba(187, 222, 222, 1) 100%
  );
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-left: 1px solid white;
  border-right: 1px solid #7b027747;
  position: absolute;
  box-shadow: -1px -1px 5px rgb(41 169 139 / 28%);
  animation-delay: 2s;
  animation-duration: 2s;
  /* animation-name: disappear-bubble; */
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.bubble::after {
  content: "";
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 66%;
  top: 20%;
  transform: skew(-0deg, 40deg) rotate(125deg);
  border-top-right-radius: 50%;
  background: linear-gradient(
    90deg,
    rgb(255 255 255) 0%,
    rgb(255 255 255 / 0%) 50%
  );
  border-radius: 50%;
}

.bubble::before {
  content: "";
  width: 2rem;
  height: 3rem;
  position: absolute;
  left: 20%;
  top: 50%;
  transform: rotate(296deg) skew(-22deg, 34deg);
  border-top-right-radius: 50%;
  background: linear-gradient(
    89deg,
    rgb(255 255 255) 0%,
    rgb(255 255 255 / 0%) 26%
  );
  border-radius: 50%;
}

.soup-layer {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 22%,
    rgba(220, 125, 215, 0) 47%,
    rgb(204 68 197 / 13%) 79%,
    rgb(27 191 35 / 6%) 93%
  );
  transform: rotate(45deg);
  border-right: 1px solid #00000030;
}

.dotted-layer {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: -1%;
  top: -1%;
  opacity: 0;
}

.dotted-layer.pink {
  border: 2px dotted #a24ea269;
  animation-delay: 2s;
  animation-duration: 2s;
  animation-name: disappear-dots-pink;
  animation-iteration-count: 1;
}

.dotted-layer.white {
  border: 4px dotted white;
  animation-delay: 1s;
  animation-duration: 2s;
  animation-name: disappear-dots-white;
  animation-iteration-count: 1;
}

/* disappear bubble */
@keyframes disappear-bubble {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes disappear-dots-pink {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale(1.1, 1.1);
  }
}
@keyframes disappear-dots-white {
  from {
    opacity: 1;
    transform: scale(0.95, 0.95);
  }

  to {
    opacity: 0;
    transform: scale(1.2, 1.2);
  }
}

.animate-word {
  z-index: 50;
  position: absolute;
  top: 135px;
  left: 48rem;
}
.images7 {
  right: 20rem;
}

.transaction {
  top: 40%;
  border-radius: 25px 25px 0px 0px;
  background: var(--background-color);
  border-top: 2px solid var(--primary-background-color);
}

.transaction-type {
  border-radius: 20px;
  border: 1px solid grey;
  padding: 5px 10px;
}

.transaction-details {
  border: 1px solid grey;
  border-radius: 10px;
}
.animate-image {
  top: -160px;
}
.white-bg {
  background-color: var(--bg-home-slide-color);
}
.transition-div {
  transition: width 0.5s ease;
}
.subscription-list-title-font-size {
  font-size: 16px !important;
}
.subscription-list-subtitle-font-size {
  font-size: 12px !important;
}
.b-a,
.bar:after,
.bar:before,
.bar {
  transition: all 0.25s;
  content: "";
  position: absolute;
  right: 0;
  height: 1px;
  width: 30px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.95);
}

.menu-collapsed {
  transition: all 0.25s;
  position: fixed;
  top: 7px;
  right: 9px;
  height: 36px;
  width: 36px;
  z-index: 1;
  cursor: pointer;
}
.menu-collapsed nav {
  position: relative;
  right: -90px;
}
.menu-collapsed ul {
  transition: all 0s;
  position: fixed;
  left: -9000px;
}

.bar {
  position: fixed;
  right: 12px;
  top: 24px;
}
.bar:before {
  top: -8px;
}
.bar:after {
  top: 8px;
}

.b-a-expanded,
.menu-expanded .bar:after,
.menu-expanded .bar:before {
  transition: all 0.25s;
  top: 0px;
}

.menu-expanded {
  z-index: 10;
  transition: all 0.25s;
  line-height: 90px;
  height: 100%;
  width: 100%;
  border-radius: 0px;
  top: 0;
  left: 0;
  background-color: var(--bg-home-page-color);
}
.menu-expanded ul {
  transition: all 0s;
  position: relative;
  width: 30%;
  left: 0;
  z-index: 2;
  height: 70vh;
  overflow-y: scroll;
}
.menu-expanded a {
  transition: all 0.15s;
  text-decoration: none;
  font-size: 2em;
  width: 100%;
  padding: 5px;
  color: var(--text-home-color);
  display: block;
}
.menu-expanded li:hover {
  transition: all 0.15s;
  transform: translateX(20px);
  border: 1px solid var(--border-menu-color);
}
.menu-expanded .bar {
  background-color: transparent;
  transition: all 0.25s;
}
.menu-expanded .bar:before {
  transform: rotate(45deg);
}
.menu-expanded .bar:after {
  transform: rotate(-45deg);
}
.privacy-policy-head {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25%;
  position: absolute;
  opacity: 0;
}
.privacy-policy-section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 23%;
  opacity: 0;
}
.about-us-head {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 14%;
  opacity: 0;
}
.about-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 23%;
  opacity: 0;
}
.contact-us-head {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.contact-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 48%;
  opacity: 0;
}
.contact-us-box {
  border: 1px solid grey !important;
  border-radius: 8px !important;
}
.contact-inputs ::placeholder {
  color: black !important;
}
.contact-inputs textarea ::placeholder {
  color: black !important;
}
.contact-inputs {
  color: var(--input-value-color);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 21px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-top: 0.25rem;
  background-color: transparent;
  border-bottom-width: 2px;
  height: 40px;
}
.parallax {
  position: relative;
  object-fit: contain;
  background-image: url("../assets/image/raimond-klavins-UvWx67Ivqe0-unsplash.jpg");
  height: 100%;
  opacity: 1;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax-2 {
  background-image: url("../assets/image/lasma-artmane-2hKNq2rX93g-unsplash.jpg");
  height: 100%;
  opacity: 1;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.div-to-animate {
  background-color: var(--bg-home-slide-color);
}
.quote-bg {
  background-image: url("../assets/image/meditating-female-is-relaxing-mountains.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  position: relative;
}
.social-menu ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-menu ul li {
  list-style: none;
  margin: 0 15px;
}

.social-menu ul li .fab {
  font-size: 30px;
  line-height: 60px;
  transition: 0.3s;
  color: var(--bg-home-page-color);
}

.social-menu ul li .fab:hover {
  color: var(--text-home-color);
}

.social-menu ul li a {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--bg-home-slide-color);
  text-align: center;
  transition: 0.6s;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.5);
}

.social-menu ul li a:hover {
  transform: translate(0, -6%);
}

.social-menu ul li:nth-child(1) a:hover {
  background-color: var(--instagram-icon-color);
}
.social-menu ul li:nth-child(2) a:hover {
  background-color: var(--linked-in-icon-color);
}
.social-menu ul li:nth-child(3) a:hover {
  background-color: var(--bg-card-insights-color);
}
.social-menu ul li:nth-child(4) a:hover {
  background-color: var(--linked-in-icon-color);
}

li:hover .svg-icon {
  fill: white;
}
.expend-course {
  transform: rotate(-90deg);
  width: 176px;
  position: absolute;
  background-color: var(--primary-button-color);
  top: 185px;
  right: -69px;
  z-index: 50;
  padding: 0 5px 0 10px;
  border-radius: 10px 10px 0px 0px;
}

.left-transition {
  transition: left 5s ease;
}

.course-box {
  height: 500px;
  overflow: auto;
  border-bottom: 1px solid #c9c3c3;
}
.subscription-list-title-font-size {
  font-size: 16px !important;
}
.subscription-list-title-font-size {
  font-size: 16px !important;
}
.subscription-list-subtitle-font-size {
  font-size: 12px !important;
}

.text-subscribed {
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 15px;
  background-color: var(--bg-event-detail-color);
  font-weight: 400;
  z-index: 1;
  text-transform: uppercase;
  font-size: 9px;
  width: fit-content;
}

.subscription-rupee {
  height: 9px;
  width: 9px;
  padding-top: 4px;
}

/* modal css */
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 999;
}

.modal-content-up {
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  border-radius: 8px 8px 0 0;
  /* padding: 20px; */
  animation: slide-up 0.5s ease forwards;
}
.modal-content-down {
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  border-radius: 8px 8px 0 0;
  padding: 20px;
  animation: slide-down 0.5s ease forwards;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  100% {
    transform: translateY(100%);
    opacity: 1;
  }
  0% {
    transform: translateY(0);
    opacity: 0;
  }
}

.help-cross-icon {
  position: absolute;
  top: 2px;
  right: 0;
}
/* video player css  */
:root {
  --plyr-color-main: var(--primary-background-color);
}
.plyr-control-spacing {
  padding: 0 !important;
}

.plyr__progress__container {
  min-width: 40px !important;
}

.plyr__volume input[type="range"] {
  min-width: 10px !important;
}

.plyr__controls {
  padding: 5px !important;
}

.plyr__control {
  padding: 5px !important;
}
.plyr {
  min-width: 100%;
}
/* end of video player css */

/* Region Zoom */
/* .zmmtg-root .mobile-suspension-window-wrapper {
  left: 10px !important;
  inset: 44px auto auto 10px !important;
}

.zmmtg-root .mobile-suspension-window-container {
  transform: translate(0px, 0px) !important;
} */

.hide-zoom-control-footer .footer__btns-container {
  display: none !important;
}

.hide-zoom-control-footer .meeting-info-container {
  display: none !important;
}
/* Endregion Zoom */

.close-model {
  position: absolute;
  right: 0;
  top: -55px;
  border-radius: 22px;
}
.scrolling-space {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
}

.filters button {
  padding: 10px 15px;
  border-radius: 10px;
  transition: 150ms;
  font-size: 14px;
  cursor: pointer;
}

.filters button.is-checked {
  background-color: #fff;
  color: #121212;
}
.insight-shadow {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.task-panding {
  height: 18px;
  width: 18px;
  background: #c9c9c9;
  border-radius: 50%;
}
.css-1m9128y {
  margin-top: 20px !important;
}
.sign-in-button {
  background: rgb(216, 13, 255);
  background: linear-gradient(
    90deg,
    rgb(209, 43, 43) 3%,
    rgb(213, 78, 78) 49%,
    rgb(120, 46, 185) 100%
  );
}
.nav-mobile-public {
  display: block;
}
.nav-public {
  display: none;
}

.custom-progress-line {
  --progress-stroke-color: var(--progress-stroke-color);
}

.link-color {
  color: var(--primary-link-color) !important;
}

.remove-scrollbar-width {
  scrollbar-width: none !important;
}

.bread-crumb-border {
  border-radius: 5px;
  border: 1px solid var(--primary-button-color);
}

.home-page-card-web {
  height: 162px;
  width: 288px;
  border-radius: 10px;
}
.home-page-card {
  height: 113px;
  width: 200px;
  border-radius: 10px;
}
.home-page-card-trend {
  height: 194px;
  width: 345px;
  border-radius: 10px;
}

/* Service Public Carousel */

.carousel {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  font-family: Arial;
}
.carousel__list {
  display: flex;
  list-style: none;
  position: relative;
  width: 100%;
  height: 450px;
  justify-content: center;
  perspective: 300px;
}
.carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0px;
  width: 500px;
  height: 400px;
  border-radius: 12px;
  /* box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.5); */
  position: absolute;
  transition: all 0.5s ease-in-out;
}

.carousel-item[data-pos="0"] {
  z-index: 5;
}
.carouse__item[data-pos="-1"],
.carousel-item[data-pos="1"] {
  opacity: 0.7;
  filter: blur(1px) grayscale(10%);
}
.carousel-item[data-pos="-1"] {
  transform: translateX(-40%) scale(0.9);
  z-index: 4;
}
.carousel-item[data-pos="1"] {
  transform: translateX(40%) scale(0.9);
  z-index: 4;
}
.carousel-item[data-pos="-2"],
.carousel-item[data-pos="2"] {
  opacity: 0.4;
  filter: blur(3px) grayscale(20%);
}
.carousel-item[data-pos="-2"] {
  transform: translateX(-70%) scale(0.8);
  z-index: 3;
}
.carousel-item[data-pos="2"] {
  transform: translateX(70%) scale(0.8);
  z-index: 3;
}
.service-image:hover .service-detail {
  background-color: var(--bg-offering-chevron);
  transition: background-color 0.5s ease-in-out;
  display: block;
}

.service-image:hover .service-detail {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
This .service-image:hover > img {
  transition: all 0.5s ease-in-out;
  transform: scale(1.2);
}
.service-title {
  background-color: var(--bg-offering-chevron);
}
.service-image:hover .service-title {
  transition: all 0.5s ease-in-out;
  display: none;
}
.mobile-carousel {
  display: none;
}
.desktop-carousel {
  display: block;
}
.home-icon {
  transform: scale(2) translateY(20%) translateX(20%) !important;
}
.unauthorized-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  font-family: "Arial", sans-serif;
}

.unauthorized-content {
  text-align: center;
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.unauthorized-content h1 {
  font-size: 2.5rem;
  color: #000;
}

.unauthorized-content p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

.logout-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #2563eb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #749df7;
}

.auth-btn {
  width: 100%;
  border-radius: 9999px;
  background-color: #2563eb;
  padding: 12px 24px;
  color: white;
}

/* Keep this comment */
/* .auth-btn:hover {
  background-color: #c6c9c9;
} */

.auth-padding {
  padding: 12px 24px;
}

.dropdown-button-auth {
  position: relative;
  width: 100%;
  padding-top: 4px;
  height: 100%;
  border-color: rgb(228, 221, 221);
  border-bottom-width: 2px;
  cursor: pointer;
  text-align: left;
}

.img-load-zoom-out {
  transform: scale(1);
  animation: imgLoadZoomOut 1s forwards;
}

@keyframes imgLoadZoomOut {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.img-loader-test {
  width: 35px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid #f04966;
  border-right-color: #b63b80;
  animation: imageLoaderTest 1s infinite linear;
}
@keyframes imageLoaderTest {
  to {
    transform: rotate(1turn);
  }
}

.grey-icon {
  color: rgb(102, 97, 97);
}
.heart {
  animation: heartbeat 1s infinite ease-in-out;
  display: inline-block;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes boxShadowChange {
  0% {
    box-shadow: 0 0 15px 5px rgba(255, 46, 46, 0.7);
  }
  33% {
    box-shadow: 0 0 15px 5px rgba(0, 255, 0, 0.7);
  }
  66% {
    box-shadow: 0 0 15px 5px rgba(255, 138, 5, 0.7);
  }
  100% {
    box-shadow: 0 0 15px 5px rgba(255, 0, 0, 0.7);
  }
}
.animated-bg {
  animation: boxShadowChange 3s infinite;
}

.inner-shadow {
  position: relative;
}

.inner-shadow::before {
  content: "";
  position: absolute;
  top: 188px;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 10px -73px 45px rgb(255 255 255 / 99%);
  z-index: 1;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
.bounce {
  animation: bounce 2s infinite;
}
.public-carousel .css-1f8sh1y {
  height: 450px !important;
}
.public-event .css-1f8sh1y {
  height: 450px !important;
}
.text-sm {
  letter-spacing: 1.5px !important;
}

.mobile-number-spacing {
  font-size: 18px !important;
  letter-spacing: 1.5px !important;
}

/* stop background scrolling while modal is open */
body:has(.modal-wrapper) {
  overflow-y: hidden;
}

.growth {
  padding: 15px;
  border: 1px solid grey;
  border-radius: 12px;
}

.growth-border {
  border-bottom: 1px solid #cdcdcd;
}

.growth-icon {
  left: 155px;
  top: -20px;
}

.tooltip {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer; /* Optional: indicates that the element is clickable */
}

/* Tooltip */
.tooltip {
  position: absolute;
  top: 100%;
  left: -32px;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  z-index: 10;
  margin-top: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  display: none;
}

/* Show tooltip when container is active */
.tooltip-container.show .tooltip {
  display: block;
}

/* Tooltip triangle */
.tooltip::before {
  content: "";
  position: absolute;
  transform: translateX(-50%);
  bottom: 76%;
  left: 63%;
  border-width: 8px;
  border-style: solid;
  border-color: #333 transparent transparent transparent; /* Triangle color */
}

/* Show tooltip when container is active */
.tooltip-container.show .tooltip {
  display: block;
}

ul.course-list li.show-content h3:after {
  content: "";
}

ul.course-list {
  position: relative;
  display: block;
  height: auto;
  padding: 0px;
  margin: 0;
  margin-top: 10px;
  list-style: none;
}
ul.course-list li {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  cursor: pointer;
}
.course-list-title:after {
  transform: rotate(45deg);
}
ul.course-list li h3 {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 0 0 0 0;
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.01em;
  cursor: pointer;
}
ul.course-list li h3:after {
  content: "\f278";
  font-family: "material-design-iconic-font";
  position: absolute;
  right: 20px;
  top: 0;
  transition: all 0.2s ease-in-out;
  font-size: 18px;
}
ul.course-list li div.answer {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
ul.course-list li div.answer {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.course-list-title {
  padding: 10px;
}

.custom-border {
  border: 1px solid var(--primary-button-color) !important;
}

.custom-border-bottom {
  border-bottom: 1px solid var(--primary-button-color);
}

.skip-btn {
  border: 1px solid var(--primary-background-color);
  border-radius: 25px;
}

.free-service-label {
  background: red;
  color: white;
  padding: 5px;
  text-transform: uppercase;
  position: absolute;
  top: 12px;
  left: 3px;
  z-index: 2;
  transform: rotate(-45deg);
  font-size: 12px;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    transform: rotate(45deg);
    border-width: 20px 20px 0 20px;
    border-color: red transparent transparent transparent;
    position: absolute;
    right: -27px;
    bottom: -3px;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    transform: rotate(-45deg);
    border-width: 20px 20px 0 20px;
    border-color: red transparent transparent transparent;
    position: absolute;
    left: -27px;
    bottom: -3px;
  }
}

.login-content {
  background: #ffffffa6;
  border-radius: 30px;
}

.border-color-black {
  border-color: #030303 !important;
  border-bottom-width: 1px !important;
}

.login-placeholder::placeholder {
  color: #60606099 !important;
}

.auth-screen-spacing {
  padding: 50px;
}

.login-text {
  letter-spacing: 0.2em;
  line-height: 50.4px;
  font-size: 28px;
  color: var(--primary-title-color);
}

.welcome-text {
  line-height: 64.8px;
  letter-spacing: 0.2em;
  font-size: 36px;
  font-weight: 300;
  color: var(--primary-title-color);
}

.otp-text {
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.2em;
  line-height: 25.2px;
}

.comman-disablebtn-login {
  background-color: #f7f7f7;
  color: #343333;
  border-radius: 25px;
}

.comman-disablebtn-login:hover {
  background: #f8ffff !important;
}

.welcome-padding {
  padding: 70px 30px;
}

.otp-screen-spacing {
  padding: 52px 95px;
}
